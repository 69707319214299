<template>
  <div class="chatpdf">
    <video :src="require('@/assets/4.mp4')" autoplay loop class="vd">
      tangbao
    </video>
    <div class="pannel" style="display:none">
      <div class="fileList">
        <div class="fileTitle">话题一</div>
        <div class="fileTitle">话题2</div>
      </div>
    </div>
    <div class="chatpdfBox" style="margin-bottom: 120px">
      <div
          id="chatBox-content-demo"
          class="chatpdfLine">
        <div v-html="takingAbout"></div>
        <div v-show="show" id="nowTaking">
          <div class="chatpdfRow chatQu">
            <div class="nalPiuUJ"><img :src="require('@/assets/tangbao.png')" alt="头像"></div>
            <pre style="white-space:pre-wrap" v-html="pre" class="chatpdfContent"></pre>
          </div>
        </div>
      </div>
      <div
          style="position:fixed;left: 0; right: 0;bottom: 100px; margin: 0 auto;height: 100px;width: 150px;z-index: 9999;">
        <!--        <el-button style="float: left;margin-top: 50px" type="success" @click="again">重新开始</el-button>-->
        <div style="float: left;width: 150px" @click="onon">


          <el-image @click="translationStart" v-show="!visidialog" :src="require('@/assets/start.png')"></el-image>

          <el-image @click="translationEnd" v-show="visidialog" :src="require('@/assets/end.png')"></el-image>
          <el-button style="width: 98px;opacity: 0.6;background-color: #000;border-color:#dcdfe6;" type="warning"
                     @click="cancel">取消
          </el-button>
        </div>

      </div>
    </div>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
        :visible.sync="visidialog"
        v-loading="dialogloading"
    >
      <div style="position: fixed;left:0;right:0;margin:0 auto" id="siri-container"></div>
      <div ref="boxRecord" id="boxRecord" style="transform: translateY(-2px);"> <canvas width="500px" height="200px" id="recordCanvas" ref="record"></canvas></div>

      <!--      <canvas id="playCanvas" ref="play"></canvas>-->
      <h3 style="color: #388aff" id="value">{{ content }}</h3>
    </el-dialog>

  </div>
  <!--  <div style="padding: 20px;">-->
  <!--    <h1>{{ msg }}</h1>-->
  <!--    <h3>录音上传</h3>-->
  <!--    <div style="font-size:14px">-->
  <!--      <h3>录音时长：{{ recorder && recorder.duration.toFixed(4) }}</h3>-->
  <!--      <br />-->
  <!--      <el-button type="primary" @click="handleStart">开始录音</el-button>-->

  <!--      <el-button type="warning" @click="handleStop">停止录音</el-button>-->
  <!--      <br />-->
  <!--      <br />-->
  <!--      <h3>-->
  <!--        播放时长：{{ recorder && (playTime > recorder.duration ? recorder.duration.toFixed(4) : playTime.toFixed(4)) }}-->
  <!--      </h3>-->
  <!--      <br />-->
  <!--      <el-button type="primary" @click="handlePlay">播放录音</el-button>-->
  <!--      <el-button type="info" @click="handlePausePlay">暂停播放</el-button>-->
  <!--      <el-button type="success" @click="handleResumePlay">继续播放</el-button>-->
  <!--      <el-button type="warning" @click="handleStopPlay">停止播放</el-button>-->
  <!--      <el-button type="error" @click="handleDestroy">销毁录音</el-button>-->
  <!--      <el-button type="primary" @click="downloadPCM">下载PCM数据</el-button>-->
  <!--      <el-button type="primary" @click="downloadWAV">下载WAV数据</el-button>-->
  <!--      <el-button type="primary" @click="uploadRecord">上传</el-button>-->
  <!--    </div>-->

  <!--  </div>-->

</template>
<script>
import Recorder from 'js-audio-recorder'
import $ from 'jquery'
import CryptoJS from 'crypto-js'
import IatRecorder from '@/assets/js/IatRecorder';
import SiriWave from "siriwave";
var siriWave = null;
const iatRecorder = new IatRecorder('en_us', 'mandarin', '9abbbfb0')//小语种-中文方言-appId
// import "@/assets/test.js";
import startSound from '@/assets/start.mp3'
import endSound from '@/assets/end.mp3'
import axios from "axios";
// import '../assets/now/hmac-sha256'

export default {
  name: 'MyRecorder',
  props: {
    msg: String
  },
  data() {
    return {
      content: 'sdfsdf',
      speakingValue: '',
      takingAbout: `
      <div class="chatpdfRow">
      <div class="nalPiuUJ">
      <img src="` + require('@/assets/tangbao.png') + `" alt="头像"></div>
      <div class="chatpdfContent">我是机器人青宝，一个专门响应人类指令的大模型。    <br>我服务于人类，致力于让生活更美好！</div>
      </div>
`,
      nowstr: '',
      show: false,
      recorder: null,
      playTime: 0,
      timer: null,
      src: null,
      drawRecordId: null,
      drawPlayId: null,
      visidialog: false,
      dialogloading: true,
      messages: [],
      synth: null,
      u: {},
      pre: '',
      ws: null,       //定义websocket对象
      APPID: '94983b18',
      APIKey: 'ea862a19509c424780c9b80996d1900c',
      upflag: true,
      cancelFlag: 4,
      startSound: null,
      endSound: null,
      siriWave:null,

    }
  },
  mounted() {

    // this.dialogloading = false;
    // this.visidialog = true;
    this.modifyPosition();
    this.startSound = new Audio(startSound);
    this.endSound = new Audio(endSound);


    // this.scrollToBottom();
    this.synth = window.speechSynthesis
    this.u = new SpeechSynthesisUtterance();
    //汉语
    this.u.lang = 'zh-CN';
    this.u.rate = 1.2;
  },
  updated: function () {
    this.scrollToBottom();
  },
  created() {
    this.recorder = new Recorder({
      sampleBits: 16, // 采样位数，支持 8 或 16，默认是 16
      sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，Chrome 是 48000
      numChannels: 1, // 声道数，支持 1 或 2， 默认是 1
    })
    // const axios = require('axios')
    // let instance = axios.create({
    //   timeout: 400000, // 设置超时时间为1000毫秒（1秒）
    //   // 其他配置...
    // });
    //
    //
    // instance.post('http://api.1il.cn/chat.php', {
    //   messages: this.messages
    //
    // }).then(res => {
    //   console.log(res)
    // })
  },
  watch: {
    visidialog(newValue, oldValue) {
      // if (newValue){
      //
      // }
      // console.log('change',newValue,oldValue)

    },
    //  content(newValue,oldValue){
    // console.log('change',newValue);
    // console.log('oldValue',oldValue)
    //
    //  },
  },
  methods: {
    modifyPosition() {




      // let setLeft = $('#boxRecord').offset().left;
      // let setTop = $('#boxRecord').offset().top;
      // $('#siri-container').offset({
      //   top:setTop,
      //   left:setLeft
      // })

    },
    async translationStart() {
      this.startSound.play();
      this.visidialog = true;
      this.dialogloading = false;
      this.$nextTick(() => {
        // 通过 DOM API 更新文本
        if (siriWave==null){
           siriWave = new SiriWave({
            container: document.getElementById("siri-container"),
            width: 640,
            height: 200,
            style: 'ios9'
          });
        }
      })
      if (this.cancelFlag == true) {
        console.log('non');
        return;
      }

      this.synth.cancel()
      this.content = '';
      try {
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        // 用户允许访问麦克风，可以继续你的逻辑，例如录音
        console.log('麦克风访问被允许');
        // 处理stream，例如创建一个MediaRecorder对象来录音
      } catch (err) {
        console.error('无法获取麦克风访问：', err);
        // 用户拒绝访问麦克风，处理错误
      }
      this.visidialog = true;
      iatRecorder.start(this);
      this.handleStart();
    },
    translationEnd() {
      this.endSound.play();

      iatRecorder.onTextChange = (text) => {
        let inputText = text;
        this.searchData = inputText.substring(0, inputText.length - 1); //文字处理，因为不知道为什么识别输出的后面都带‘。’，这个方法是去除字符串最后一位
        console.log(this.searchData);
      };
      iatRecorder.stop();
      this.handleStop();
      this.uploadRecord();
      this.visidialog = false;
    },
    spee() {
      const utterance = new SpeechSynthesisUtterance('您需要转换的文字');
      // 获取浏览器支持的语音合成器
      const voices = speechSynthesis.getVoices();
      utterance.voice = voices[0]; // 默认使用第一个语音合成器
      speechSynthesis.speak(utterance);
    },
    again() {
      this.content = '';
      // this.handleStop();
      // this.handleDestroy();
      // this.handleStart();
      iatRecorder.stop();
      iatRecorder.start()

    },
    cancel() {
      this.cancelFlag = 4;
      this.content = '';
      iatRecorder.stop();
      this.handleStop();
      this.handleDestroy();
      this.visidialog = false;

    },
    async onon() {
      return 0;
      try {
        console.log('111');
        const stream = await navigator.mediaDevices.getUserMedia({audio: true});
        // 用户允许访问麦克风，可以继续你的逻辑，例如录音
        console.log('麦克风访问被允许');
        // 处理stream，例如创建一个MediaRecorder对象来录音
      } catch (err) {
        console.error('无法获取麦克风访问：', err);
        // 用户拒绝访问麦克风，处理错误
      }

      if (this.visidialog == false) {
        this.handleStart();
      } else {

        this.handleStop();
        this.uploadRecord()
      }
    },
    // 开始录音
    handleStart() {

      this.visidialog = true
      this.recorder = new Recorder()
      Recorder.getPermission().then(() => {
        console.log('开始录音')
        this.recorder.start() // 开始录音
        this.drawRecord();
        // setTimeout(()=>{
        //   console.log('11')
        //  const tt= this.recorder.getWAVBlob()
        //   console.log(tt);
        //
        // },1000)
      }, (error) => {
        this.$message({
          message: '请先允许该网页使用麦克风',
          type: 'info'
        })
        console.log(`${error.name} : ${error.message}`)
      })
    },
    initWebSocket() {
      let ts = new Date().getTime() / 1000;
      let baseString = this.APPID + ts;
      let baseSMd5 = CryptoJS.MD5(baseString).toString();
      let sha1 = CryptoJS.HmacSHA1(baseSMd5, this.APIKey).toString();
      //初始化websocket    userId为会话标识
      const wsuri = `ws://rtasr.xfyun.cn/v1/ws?appid=${this.APPID}&ts=${ts}&signa=${sha1}`;
      //连接服务端
      this.ws = new WebSocket(wsuri);
      //指定事件回调
      this.ws.onmessage = this.websocketOnMessage;
      this.ws.onopen = this.websocketOnOpen;
      this.ws.onerror = this.websocketOnError;
      this.ws.onclose = this.websocketClose;
    },
//连接建立之后的回调
    websocketOnOpen() {
      this.ws.send("握手成功");
      console.log("--------连接已建立!---------")
    },
//数据接收
    websocketOnMessage(e) {
      console.log("收到了服务端语音识别后的数据" + e)
    },
//数据发送
    websocketSend(Data) {
      //将音频数据发送到后端
      this.ws.send(Data);
    },
//连接建立失败重连
    websocketOnError() {
      this.initWebSocket();
    },
//关闭
    websocketClose(e) {
      console.log('断开连接', e);
    },
    handlePause() {
      console.log('暂停录音')
      this.recorder.pause() // 暂停录音
    },
    handleResume() {
      console.log('恢复录音')
      this.recorder.resume() // 恢复录音
    },
    handleStop() {
      console.log('停止录音')
      this.drawRecordId && cancelAnimationFrame(this.drawRecordId);
      this.drawRecordId = null;
      this.recorder.stop() // 停止录音
    },

    handlePlay() {
      console.log('播放录音')
      console.log(this.recorder)
      this.drawPlay();
      this.recorder.play() // 播放录音

      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    drawRecord() {
      // 用requestAnimationFrame稳定60fps绘制
      this.drawRecordId = requestAnimationFrame(this.drawRecord)
      this.drawWave({
        canvas: this.$refs.record,
        dataArray: this.recorder.getRecordAnalyseData(),
      });
    },

    drawPlay() {
      // 用requestAnimationFrame稳定60fps绘制
      this.drawPlayId = requestAnimationFrame(this.drawPlay);
      this.drawWave({
        canvas: this.$refs.play,
        dataArray: this.recorder.getPlayAnalyseData(),
      });
    },
    // 绘制波形图
    drawWave({canvas, dataArray}) {
      const ctx = canvas.getContext("2d");
      const bufferLength = dataArray.length;
      // 填充背景色
      ctx.globalAlpha = 0.5;
      ctx.fillStyle = "rgba(255, 255, 255, 0)";
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // ctx.fillRect(0, 0, canvas.width, canvas.height);
      // 设定波形绘制颜色
      ctx.lineWidth = 3;
      ctx.strokeStyle = "#409EFF";

      ctx.beginPath();
      var sliceWidth = (canvas.width * 1.0) / bufferLength, // 一个点占多少位置，共有bufferLength个点要绘制
          x = 0; // 绘制点的x轴位置
      for (var i = 0; i < bufferLength; i++) {
        var v = dataArray[i] / 128.0;
        var y = (v * canvas.height) / 2;
        if (i === 0) {
          // 第一个点
          ctx.moveTo(x, y);
        } else {
          // 剩余的点
          ctx.lineTo(x, y);
        }
        // 依次平移，绘制所有点
        x += sliceWidth;
      }
      ctx.lineTo(canvas.width, canvas.height / 2);
      ctx.stroke();
    },
    handlePausePlay() {
      console.log('暂停播放')
      this.recorder.pausePlay() // 暂停播放

      // 播放时长
      this.playTime = this.recorder.getPlayTime()
      this.time = null
    },
    handleResumePlay() {
      console.log('恢复播放')
      this.recorder.resumePlay() // 恢复播放

      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    handleStopPlay() {
      console.log('停止播放')
      this.recorder.stopPlay() // 停止播放

      // 播放时长
      this.playTime = this.recorder.getPlayTime()
      this.timer = null
    },
    handleDestroy() {
      console.log('销毁实例')
      this.recorder.destroy() // 销毁实例
      this.timer = null
      this.cancelFlag = false;
    },
    downloadPCM() {
      console.log('下载PCM格式数据')
      // 注：使用该方法会默认调用 stop() 方法
      this.recorder.downloadWAV('record-pcm')
    },
    downloadWAV() {
      console.log('下载WAV格式数据')
      // 注：使用该方法会默认调用 stop() 方法
      this.recorder.downloadWAV('record-wav')
    },
    tttt() {


    },
    scrollToBottom: function () {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#chatBox-content-demo");
        container.scrollTop = container.scrollHeight;
      })
    },
    uploadRecord() {
      if (this.content == '') {
        this.$message.error('没有内容请重新进行对话')
        return;
      }
      const charturl = 'https://api.1il.cn/chat.php';
      const axios = require('axios')
      const instance = axios.create({
        timeout: 180000, // 设置超时时间为1000毫秒（1秒）
        // 其他配置...
      });

      let tempres = this.content;
      this.content = '';

      let qstr = tempres;
      this.nowstr = qstr;
      this.messages.push({
        "role": 'user',
        "content": qstr
      })
      this.handleDestroy();
      this.visidialog = false;
      if (this.pre != '') {
        this.takingAbout = this.takingAbout + $('#nowTaking').html();
        console.log('@@', $('#nowTaking').html());
        this.pre = '';
      }
      this.takingAbout = this.takingAbout + `
        <div class="chatpdfRow chatpdfAsk">
                    <div class="chatpdfContent">${qstr}</div>
          <div class="nalPiuUJ"><img src=` + require("@/assets/user.png") + `  alt="头像"></div>
                </div>
        `

      instance.post(charturl, {
        messages: this.messages
      }).then(res => {
        this.messages.push(
            {
              "role": 'assistant',
              "content": res.data.result
            }
        )
        let tt = res.data.result;
        tt = tt.replace('/n', '<br>');
        let len = tt.length;
        let i = 0;
        this.show = true;
        let timer = setInterval(() => {
          var randomNumber = Math.floor(Math.random() * 10) + 1;
          this.pre += tt.substring(i, i + randomNumber)
          console.log($('.chatpdfContent:last'))
          i += randomNumber;
          if (i > len) {
            clearInterval(timer);
          }
          let chatQu = $('.chatQu:last')[0];
          // chatBox-content-demo
          chatQu.scrollIntoView({behavior: 'smooth'});
        }, 100)
        this.u.text = res.data.result;
        this.synth.speak(this.u)
        this.synth.resume(this.u)
      })

    },

  }
}
</script>
<style>
::-webkit-scrollbar {
  height: 0;
  width: 0;
  color: transparent;
}

.chatpdf {
  display: flex;
  height: 100vh;
  flex-direction: row;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Ubuntu, Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Source Han Sans CN, sans-serif, Apple Color Emoji, Segoe UI Emoji !important;
  list-style: none;
  -webkit-font-smoothing: antialiased;

  .container {

    overflow: hidden;
  }
}

.chatpdf .pannel {
  width: 255px;
  background-color: rgb(0, 21, 41);
}

.chatpdfBox {
  display: flex;
  flex-direction: column;
  flex: 1;
  /*background: linear-gradient(to bottom right,#dbe6fb, #f3f4f8);*/
  background-size: cover;
  background-attachment: fixed;
}

.chatpdfLine {
  flex: 1;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  overflow-y: auto;
}

.chatpdfRow {
  margin: 20px 10px;
  display: flex;
}

.chatpdfAsk {
  justify-content: flex-end;
}

.chatpdfContent {
  text-align: left;
  display: inline-block;
  border-radius: 8px;
  padding: 6px 12px;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.6);
  font-size: 15px;
  margin-left: 0px;
  color: #05073b;
  cursor: auto;
  font-weight: 400;
  line-height: 1.75;
  overflow-wrap: break-word;
  word-break: normal;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
}

.chatpdfContent2 {
  display: inline-block;
  border-radius: 8px;
  padding: 6px 12px;
  max-width: 800px;
  background: rgba(255, 255, 255, 0.6);
  font-size: 13px;
  color: #05073b;
  cursor: auto;
  font-weight: 400;
  line-height: 1.75;
  overflow-wrap: break-word;
  word-break: normal;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.12), 0px 1.6px 3.6px rgba(0, 0, 0, 0.16);
}

.chatpdfAsk .chatpdfContent {
  background: linear-gradient(90deg, #2870EA 10.79%, #1B4AEF 87.08%);;
  color: #fff;
  margin-right: 8px;
  font-weight: bolder;
}

.chatpdfArea {
  display: flex;
  padding: 5px 10px;
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}

.chatpdfArea textarea {
  flex: 1;
  border-color: #d9d9d9;
  resize: none;
  outline: none;
  padding: 0px 5px;
  height: 65px;
  line-height: 30px;
  color: #404040;
  border-radius: 10px 0px 0px 10px;
  transition: all 0.3s, height 0s;
}

.chatpdfArea textarea:hover {
  border-color: #4096ff;
}

.chatpdfArea button {
  /*height: 65px;*/
  color: #fff;
  background: linear-gradient(90deg, #2870EA 10.79%, #1B4AEF 87.08%);
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  border: none;
  padding: 0 20px;
  border-radius: 0px 8px 8px 0px;
  cursor: pointer;
}

.chatpdfArea button:hover {
  background-color: #388aff;
}


.nalPiuUJ img {
  border-style: none;
  width: 44px;
  padding-right: 10px;
  vertical-align: middle;
}


.chatpdf .fileTitle {
  background-color: #1677ff;
  color: #fff;
  border-radius: 8px;
  padding: 10px;
  margin: 10px;
  font-size: 14px;
  cursor: pointer;
}

.vd {
  position: fixed;
  right: 0px;
  bottom: 0px;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;
  z-index: -1;
}

@media (max-width: 768px) {
  .pannel {
    display: none;
  }
}

#value {
  font-size: 22px;
  padding: 10px 0 4px 10px;
}

.el-dialog {
  height: 600px;
  background-color: rgba(255, 255, 255, 0);


}

.el-loading-mask {
  background-color: rgba(40, 40, 40, 0.7);
}

</style>
